import { nanoid } from 'nanoid';
import chocolate from '../images/chocolate.jpg';
// HEAD DATA
export const headData = {
  title: 'Aviad Hadad | Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Aviad Hadad',
  subtitle: `I'm a software developer.`,
  cta: 'See more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: `A 33 years old full-stack developer, based in Tel Aviv. My main tools of the trade are Node.JS, React and MySQL (+ Angular.js r.i.p), but I'm passionate about all things tech related, and always eager to learn new technologies. The magic of building software from scratch and watching it eventually work still excites me like the day I started.`,
  paragraphTwo: `I didn't end up a full stack developer by accident, I'm always curious to learn how things work, and try to knowledgeable about every part of what makes a webapp "tick", starting from the system and networking level, up to the client side.`,

  paragraphThree: `In my spare time I like answering Typescript questions on <a href="https://stackoverflow.com/users/1930482/aviad-hadad">stack overflow</a> (Yes, I <b>really</b> like Typescript), baking and making <a target="_blank" href="${chocolate}">chocolate pralines</a>, and once upon a time we used to have these things called "Live shows and festivals" and I used to go to as many as I could. Currently I'm studying for a Masters degree in Computer Science at IDC Herzliya.`,
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'quix-screenshot.png',
    title: 'Quix',
    info: `an easy-to-use notebook manager for various big data query engines, like Presto, Athena, BigQuery and many more.`,
    info2: `I am part of a team of 3 who are the core contributes to the project. I was in charge of the Node.js management server which manages users and user data. On the client side I contributed to the text editing experience by enabling sql syntax validation and basic auto-complete, as well as many other minor features.
    
    Technologies used: Mysql, Node.js + Nest.js, Angular.js`,
    url: 'https://quix-demo.io/#/home',
    repo: 'https://github.com/wix/quix', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'spot-last-filter.png',
    title: 'spotify-last-fm-filter',
    info: `I really like Music. I like new Music. Sometimes Spotify suggests songs to me I already heard. This tool was meant to filter those songs.`,
    info2: `I made this tool mainly because I wanted to try out writing vanilla Javascript again, and see how far I could go with modern browsers. No webpack, no complicated build process, just use es modules in the browser. No React, not even jquery, just me and the DOM.

    My plan to the future is to write this again with other frontend frameworks, and with web components, and see how it compares.
    
    Technologies used: Node.js + Express, ES2016, AWS elastic beanstalk`
    ,
    url: 'https://filter-discover-weekly.sthuck.info/',
    repo: 'https://github.com/sthuck/spotify-last-fm-filter', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'duckduck-screenshot.png',
    title: 'DuckDuckStay',
    info: 'a POC-level web search engine, done as a student project.',
    info2: 'Technologies used: Node.js + Express, Elasticsearch, AWS ECS, SQS, DynamoDB, Terraform, Puppeteer',
    demo: 'https://www.youtube.com/watch?v=7Epc8jjbynI&feature=youtu.be',
    repo: 'https://github.com/sthuck/duckduckstay', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'aviad@sthuck.info',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/aviad-hadad-29561887/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/sthuck',
    },
    {
      id: nanoid(),
      name: 'lastfm',
      url: 'https://www.last.fm/user/aviadhadad',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
